<template>
	<div v-click-outside="inactive">
		<div class="vf-input-type-image">
			<img src="../assets/calendar.png" alt="">
		</div>
		<div class="date-time-wrapper">
			<div class="date-time-block">
				<div class="calendar-wrapper">
					<label>Pickup date</label>
					<input type="hidden" name="fields[pickupDate]" id="pickup" :value="hiddenPickupDate">
					<datepicker id="pickup-datepicker"
					v-click-outside="hideCalendar"
					input-class="datepicker-input"
					calendar-class="datepicker-calendar"
					wrapper-class="datepicker-wrapper"
					ref="pickup-datepicker"
					v-model="pickupDate"
					:disabledDates="pickUpPicker.disabledDates"
					@closed="changeFocusPick"></datepicker>
				</div>
				<div class="time-display"
				@click="active">
					<label>Time</label>
					<span> {{hours}} : {{minutes}} </span>
				</div>

			</div>
			<div
			v-if="isActiveDate"  class="time-dropdown">
				<div class="time-message">
					<span>
						Estimated time for your ride:
					</span>
					<span>
						<strong>{{distance}}</strong>
					</span>
				</div>
				<div class="time-select">
					<select name="" id=""
					v-model="hours"
					@change="checkDropdown">
						<option value="Hr" disabled>Hr</option>
						<option
						v-for="hour in hourArray"
						:key="hour.id"
						:value="hour">
							{{ hour }}
						</option>
					</select>
					<select name="" id=""
					v-model="minutes"
					@change="checkDropdown">
						<option value="Min" disabled>Min</option>
						<option
						v-for="minute in minutesArray"
						:key="minute.id"
						:value="minute">
							{{ minute }}
							</option>
						</select>
				</div>
			</div>
		</div>

		<div id="error-pick-date" v-if="error" class="validation-error">
			<span>Please specify a pickup date and time</span>
		</div>

	</div>
</template>


<script>
	import { bus } from '../main.js';
	import clickOutside from '../directives/clickoutside.js'
	// DatePicker
	import Datepicker from 'vuejs-datepicker';

	// var tomorrow = new Date(+new Date() + 86400000).toISOString().substr(0, 10);
	var tomorrow = new Date(+new Date() + 86400000);

	export default {
		directives: {
			clickOutside
		},
		components: {
			'datepicker': Datepicker
		},
		data() {
			return {
				tomorrow: tomorrow,
				pickupDate: '',
				time: '',
				displayReturn: true,
				hasOpened: false,
				error: false,
				distance: '',
				hours: 'Hr',
				minutes: 'Min',
				hourArray: ['06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
				minutesArray: ['00', '10', '20', '30', '40', '50'],
				isActiveDate: false
			}
		},
		computed: {
			pickUpPicker() {
				return{
					disabledDates: {
						to: new Date()
					}
				}
			},
			hiddenPickupDate: {
				get: function() {
					// formats the date passed to the hidden input
					let dateTime;
					if ((this.pickupDate != '') && (this.time != '')) {
						dateTime = this.pickupDate.toLocaleString('fr-FR').substr(0,10) + ', ' + this.time;
					bus.$emit('selectedPickupDate', {pickupDate: this.pickupDate, distance: this.distance})
					} else if ((this.pickupDate == '') && (this.time == '')){
						dateTime = ''
					}
					return dateTime
				},
				set: function(newValue) {
					this.pickupDate = newValue;
					this.time = newValue;
				}
			}
		},
		methods: {
			changeFocusPick() {
				setTimeout(()=> this.isActiveDate = true, 40)
				if(this.time) {
						this.error = false;
					}
			},
			hideCalendar() {
				this.$refs['pickup-datepicker'].close();
			},
			// hides time dropdown
			inactive() {
				this.isActiveDate = false;
			},
			active() {
				setTimeout(()=> this.isActiveDate = true, 40)
			},
			checkDropdown() {
				if((this.hours != 'Hr') && (this.minutes != 'Min')) {
					this.isActiveDate = false;
					this.time = this.hours + ':' + this.minutes;
					if(!this.displayReturn) {
						if(!this.hasOpened) {
							setTimeout(() => bus.$emit('moveToNum'), 10)
						}
					} else {
						setTimeout(() => bus.$emit('moveToDropoff'), 10)
					}
					if(this.pickupDate) {
						this.error = false;
					}
				}
			},
			googleDistance(origin, destination) {
				var service = new google.maps.DistanceMatrixService();
				let self = this;
				service.getDistanceMatrix({
					origins: [origin],
					destinations: [destination],
					travelMode: 'DRIVING',
					unitSystem: google.maps.UnitSystem.METRIC,
					avoidHighways: false,
					avoidTolls: false
					}, function(response, status) {
						return new Promise((resolve,reject) => {
							if (status !== 'OK') {
								reject(new Error('Error was: ' + status))
							} else {
								resolve(response.rows[0].elements[0].duration.text)
							}
						}).then(result => self.distance = result)
				})
			}
		},
		created() {
			bus.$on('DropoffLocationSelected', (data) => {
				setTimeout(() => this.$refs['pickup-datepicker'].showCalendar(), 10)
				var origin = data.dropoffLocation + ', Kriti';
				var destination = data.pickupLocation.location + ', Kriti';
				this.googleDistance(origin, destination);
				// document.getElementById('pickup-datepicker').focus()
			}),
			bus.$on('preselect', data => {
				let origin = data.origin.location + ', Kriti';
				let destination = data.destination.location + ', Kriti';
				this.googleDistance(origin,destination);

			}),
			bus.$on('clearForm', () => {
				this.hiddenPickupDate = '';
				this.hours = 'Hr';
				this.minutes = 'Min';
				this.hasOpened = false;
			}),
			bus.$on('pickupDateError', () => this.error = true),
			bus.$on('dropoffDateDisabled',() => this.displayReturn = false),
			bus.$on('dropoffDateEnabled', () => this.displayReturn = true),
			bus.$on('hasOpened', () => this.hasOpened = true)
		}
	};
</script>


<style scoped>


@media screen and (max-width: 600px) {
	.time-dropdown {
		left: 30%;
	}
}
</style>

<style>

.date-time-block {
	background-color: #fff;
	display: flex;
	height: 60px;
}

.date-time-wrapper {
	width: calc(100% - 60px);
	height: 0px;
	font-family: 'Roboto Condensed', sans-serif;
}

.calendar-wrapper {
	height: 60px;
	width: 70%;
	border-right: 1px solid #0d7dbc;
	display: flex;
	flex-direction: column;
	align-content: stretch;
}

.calendar-wrapper label {
	height: 26px;
	color: #03a9f4;
	font-weight: 600;
	padding: 3px 0px 0px 6px;
	margin: 0;
}

#pickup-datepicker {
	width: 100%;
	border: none;
	height: 32px;
}

.datepicker-calendar {
	width: calc(143% + 4px);
	z-index: 10000000000;
}

.datepicker-input {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 16px;
	padding-left: 6px;
	letter-spacing: 2px;
	outline: none;
}

.time-dropdown {
	width: 60%;
	position: relative;
	background-color: #fff;
	padding: 15px;
	top: 15px;
	left: 40%;
	z-index: 999999999;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 1px solid #666;
}

.time-dropdown::before {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 60%;
  margin-left: -5px;
  border-width: 16px;
  border-style: solid;
  border-color: transparent transparent #666 transparent;
  z-index: 1;
}

.time-dropdown::before {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 60%;
  margin-left: -6px;
  border-width: 16px;
  border-style: solid;
  border-color: transparent transparent #666 transparent;
  z-index: 1;
}

.time-dropdown::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 60%;
  margin-left: -5px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  z-index: 1;
}

.time-display {
	font-size: 14px;
	cursor: pointer;
	width: 30%;
	height: 60px;
	padding-left: 6px;
	display: flex;
	flex-direction: column;
	align-content: stretch;
	justify-content: space-around;
}

.time-display label {
	color: #03a9f4;
	font-weight: 600;
	cursor: pointer;
	/*padding: 6px 0 0 6px;*/
}

.time-message {
	padding: 4px 0;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.time-select {
	display: flex;
	justify-content: space-around;
}

.time-dropdown select {
	padding: 2px;
	font-size: 16px;
	width: 60px;
}

.time-message span {
	padding: 1px 0;
}

@media screen and (max-width: 330px) {
	.date-time-block {
		font-size: 16px;
	}
	.time-dropdown {
		font-size: 16px;
	}
}
</style>
<template>
	<div v-click-outside="inactive">
		<div class="vf-input-type-image">
			<img src="../assets/user.png" alt="">
		</div>
		<div id="passengers"
		@click="active">
			<input type="hidden" :value="passengers" id="passengers-input" name="fields[passengers]">
			<label>Passengers</label>
			<span> {{passengers}}</span>
		</div>
		<div id="dropdown-content-num"
		v-if="isActive">
			<p>Please select the total number of passengers including infants and children</p>
			<div>
				<button class="sin-plin" @click.prevent="decrease">-</button>
				<span>{{passengers}}</span>
				<button class="sin-plin" @click.prevent="increase">+</button>
			</div>
			<button id="set-number" @click.prevent="isActive = false">SET</button>
		</div>

	</div>
</template>


<script>
	import clickOutside from '../directives/clickoutside.js';
	import { bus } from '../main.js';

	export default {
		directives: {
			clickOutside
		},
		data() {
			return {
				passengers: 2,
				isActive: false,
				hasOpened: false
			}
		},
		methods: {
			decrease() {
				if((this.passengers > 1) && (this.passengers <= 11)){
					this.passengers -= 1;
				}
			},
			increase() {
				if((this.passengers >= 0) && (this.passengers < 11)){
					this.passengers += 1;
				}
			},
			active() {
				((this.isActive == true) ? this.isActive = false : this.isActive = true)
			},
			// hides time dropdown
			inactive() {
				this.isActive = false;
			}
		},
		created() {
			bus.$on('clearForm', () => this.passengers = 2),
			bus.$on('moveToNum', () => {
				this.isActive = true;
				bus.$emit('hasOpened');
			}),
			bus.$on('preselect-sidebar', data => {
				this.passengers = data.passengers;
			})
		}
	};
</script>

<style scoped>
#passengers {
	cursor: pointer;
	width: calc(100% - 60px);
	height: 60px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-left: 6px;
}

#passengers label {
	color: #03a9f4;
	font-weight: 600;
}

#passengers p {
	margin: 0;
}

#dropdown-content-num {
	font-family: 'Roboto Condensed', sans-serif;
	width: 100%;
	display:flex;
	flex-direction: column;
	justify-content: space-around;
	align-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	background-color: #fff;
	padding: 5px 10px;
	top: 15px;
	/*left: 60px;*/
	z-index: 10000000000;
	border: 1px solid #666;
}

#dropdown-content-num::before {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 30%;
  margin-left: -6px;
  border-width: 16px;
  border-style: solid;
  border-color: transparent transparent #666 transparent;
  z-index: 1;
}

#dropdown-content-num::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 30%;
  margin-left: -5px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  z-index: 1;
}

#dropdown-content-num .sin-plin {
	padding: 10px;
	margin: 10px 15px;
}

#set-number {
	padding: 10px 34px;
	margin: 10px 0;
}

@media screen and (min-width: 990px) and (max-width: 1200px) {
	#passengers {
		font-size: 16px;
	}
	#dropdown-content-num {
		font-size: 14px;
	}
	#dropdown-content-num .sin-plin {
		padding: 8px;
		margin: 10px 14px;
	}
}

@media screen and (max-width: 330px) {
	#passengers {
		font-size: 16px;
	}
	#dropdown-content-num {
		font-size: 16px;
	}
}
</style>
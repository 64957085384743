<template>
	<div >
		<div id="vf-checkbox-wrapper">
			<div id="vf-checkbox-label">
				<div class="vf-tooltip">Return
					<span class="vf-tooltiptext">Discount 5%</span>
				</div>
			</div>
			<label class="switch" id="return-check">
				<input type="hidden" name="fields[return]" id="return" :value="returnOrNot">
				<input type="checkbox" id="is_twoway" name=""
				@click="toggleReturn">
				<span class="slider round"></span>
			</label>
		</div>
	</div>
</template>


<script>
	import { bus } from '../main.js';

	export default {
		data() {
			return {
				returnOrNot: '1'
			}
		},
		methods: {
			toggleReturn() {
				((this.returnOrNot == '1') ? this.returnOrNot = '0' : this.returnOrNot = '1');
				bus.$emit('switched')
			}
		}
	};
</script>

<style scoped>
#vf-checkbox-wrapper {
	color: #fff;
	font-size: 20px;
	display: flex;
}

#vf-checkbox-label {
	align-self: center;
	display: flex;
	align-items: center;
}
/*toggle button style*/

.switch {
	position: relative;
	display: inline-block;
	width: 70px;
	height: 28px;
	-webkit-tap-highlight-color: transparent;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #A5C663;
	border: 1px solid #fff;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 21px;
	width: 21px;
	right: 4px;
	bottom: 3px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: inherit;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(-38px);
	-ms-transform: translateX(-38px);
	transform: translateX(-38px);
	background: #e5e5e5;
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
	background: #fff;
}

/*tool tip*/
.vf-tooltip {
	position: relative;
	display: inline-block;
	margin-right: 10px;
}

.vf-tooltip .vf-tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}

.vf-tooltip .vf-tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.vf-tooltip:hover .vf-tooltiptext {
	visibility: visible;
	opacity: 1;
}
</style>
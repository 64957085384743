<template>

	<form id="appSidebar" autocomplete="off" method="post">
        <input type="hidden" name="action" value="commerce/cart/update-cart">
        <!-- <input type="hidden" name="redirect" :value="'/results/' + pickupSlug + '/' + dropoffSlug"> -->
		<div id="locations">
			<pickup-selector id="pickup-selector"></pickup-selector>
			<dropoff-selector id="dropoff-selector"></dropoff-selector>
		</div>
		<div id="date-pas">
			<sidebar-pickup-date-selector id="date"></sidebar-pickup-date-selector>
			<sidebar-dropoff-date-selector id="dropoff-date" v-if="returnCraft==1"></sidebar-dropoff-date-selector>
			<number-passangers id="number-passangers"></number-passangers>
			<button id="submit-button" @click="validation" type="button">UPDATE RESULTS</button>
		</div>
	</form>

</template>

<script>
import clickOutside from './directives/clickoutside.js';
import { bus } from './main.js';

const axios = require('axios');

export default {
	name: 'appSidebar',
	directives: {
			clickOutside
		},
	data() {
		return {
			pickupValid: false,
			dropoffValid: false,
			allLocations: [],
			pickupSlug: '',
			dropoffSlug: '',
			originSlug: window.craftData['fromSlug'],
			destinationSlug: window.craftData['toSlug'],
			hiddenPickupDate: window.craftData['hiddenPickupDate'],
			pickupDate: window.craftData['pickupDate'],
			pickupTime: window.craftData['pickupTime'],
			hiddenReturnDate: window.craftData['hiddenReturnDate'],
			returnDate: window.craftData['returnDate'],
			returnTime: window.craftData['returnTime'],
			passengers: window.craftData['passengers'],
			returnCraft: window.craftData['return'],
		}
	},
	methods: {
		validation() {
			if(this.pickupValid == false){
				bus.$emit('pickupError');
				if (this.dropoffValid == false) {
					bus.$emit('dropoffError');
				}
			} else if(this.dropoffValid == false) {
				bus.$emit('dropoffError');
			} else {
				let form = document.getElementById('appSidebar');
				let data = new FormData(form);
				let my = this
				axios.post('/', data)
					.then(function(response) {
						if (response.data.cart) {
							window.location.href = '/results/' + my.pickupSlug + '-to-' + my.dropoffSlug;
						}
					})
			}
		},
		preselect() {
			let origin;
			let destination;
			for(let i = 0; i < this.allLocations.length; i++) {
				if(this.allLocations[i].slug == this.originSlug) {
					origin = this.allLocations[i];
				}
				if(this.allLocations[i].slug == this.destinationSlug) {
					destination = this.allLocations[i];
				}
			}
			if((origin != undefined) && (destination != undefined)){
				bus.$emit('preselect-sidebar',
					{   origin:origin,
						destination:destination,
						hiddenPickupDate:this.hiddenPickupDate,
						pickupDate:this.pickupDate,
						pickupTime:this.pickupTime,
						hiddenReturnDate:this.hiddenReturnDate,
						returnDate:this.returnDate,
						returnTime:this.returnTime,
						passengers: Number(this.passengers),
					});
				this.pickupSlug = origin.slug;
				this.dropoffSlug = destination.slug;
			}
		}
	},
	created() {
		bus.$on('PickupLocationSelected', data =>  {
			this.pickupValid = true;
			this.pickupSlug = data.slug;
		}),
		bus.$on('removePickupError', () =>  this.pickupValid = true),
		bus.$on('noPickupLocation', () => this.pickupValid = false)
		bus.$on('DropoffLocationSelected', data => {
			this.dropoffValid = true;
			this.dropoffSlug = data.dropoffLocationSlug;
		}),
		bus.$on('removeDropoffError', () => this.dropoffValid = true),
		bus.$on('noDropoffLocation', () => this.dropoffValid = false)
	},
	mounted() {
		axios.get(' https://transferplan-crete.com/locations.json')
			.then(response => {
				this.allLocations = response.data.data;
				this.preselect();
				bus.$emit('allLocations', response.data.data)
			}),
			axios.get(' https://transferplan-crete.com/suggested.json')
			.then(response => {
				bus.$emit('suggestedLocations', response.data.data)
			})
	}
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

#appSidebar {
	font-family: 'Roboto Condensed', sans-serif;
	margin: 0;
	background-color: inherit;
	display: flex;
	flex-wrap: wrap;
	font-size: 16px;
	flex-direction: column;
}

#locations {
	margin: 0;
	flex: 100%;
	display: flex;
	flex-wrap: wrap;
	/*flex-direction: column;*/
	justify-content: space-between;
}

#pickup-selector {
	flex: 0 1 100%;
	margin: 5px 0 25px 0;
	height: 60px;
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
}

#dropoff-selector {
	flex: 0 1 100%;
	margin: 0 0 25px 0;
	height: 60px;
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
}

#date-pas {
	flex: 100%;
	display: flex;
	justify-content: space-between;
	margin: 0;
	flex-wrap: wrap;
}

#date {
	flex: 0 1 100%;
	margin: 0 0 25px 0;
	background-color: #fff;
	height: 60px;
	display: flex;
	flex-wrap: wrap;
}

#dropoff-date {
	flex: 0 1 100%;
	margin: 0 0 25px 0;
	background-color: #fff;
	height: 60px;
	width: 100%;
}

#number-passangers {
	flex: 0 1 100%;
	margin: 0 0 25px 0;
	background-color: #fff;
	/*height: 60px;*/
	display: flex;
	flex-wrap: wrap;
	align-content: stretch;
	align-items: center;
	/*border: 1px solid #03a9f4;*/
}

#submit-button {
	font-size: 18px;
	flex: 0 1 100%;
	background-color: #f5a539;
	color: #fff;
	border: 2px solid #f5a539;
	cursor: pointer;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	outline: none;
	transition: all 0.25s ease-in-out;
	border-radius: 3px;
}

#submit-button:hover {
	background: #FF6F00;
	border: 2px solid #FF6F00;
}
</style>
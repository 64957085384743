<template>
	<ul  id="List">
		<li tabindex="0"
			v-for="item in list"
			@keydown="navigateList(item, $event)"
			@mousemove="mouseMove"
			@click="clickListItem(item)"
			v-bind:key="item.id" >
			<p id="list-heading" v-if="item.type == 'heading'">{{item.location}}</p>
			<slot v-if="item.type != 'heading'" :item="item"></slot>
		</li>
	</ul>
</template>

<script>
	import { bus } from '../main.js';

	export default{
		props: {
			array: {
				type: Array
			},
			sugLength: {
				type: Number
			}
		},
		data() {
			return {

			}
		},
		computed: {
			list() {

				let tempList = [];
				//if there is no suggusted destinations, hides the headings
				if (this.sugLength <= 2) {
					tempList = this.array.filter(element => element.type != 'heading')
				} else {
					tempList = this.array
				}
				return tempList
			}
		},
		methods: {
			// pass the clicked item to the data
			clickListItem(item) {
				if(event.target.id != 'list-heading'){
					this.$emit('clickListItem', item);
				}
			},
			navigateList(item, event) {
				let list = document.getElementById('List');
				let listChilds = list.childNodes;

				if (event.code == 'ArrowDown') {
					event.preventDefault();
					if(event.target.nextSibling) {
						for(let i = 0; i < listChilds.length; i++) {
							listChilds[i].style.backgroundColor = "#fff";
						}
						if(event.target.nextSibling.firstChild.id != 'list-heading') {
							event.target.nextSibling.focus();
							event.target.nextSibling.style.backgroundColor = "#ececec";
						} else {
							event.target.nextSibling.nextSibling.focus();
							event.target.nextSibling.nextSibling.style.backgroundColor = "#ececec";
						}
					}
				} else if (event.code == 'ArrowUp') {
					event.preventDefault();
					if(event.target.previousSibling) {
						for(let i = 0; i < listChilds.length; i++) {
							listChilds[i].style.backgroundColor = "#fff";
						}
						if (event.target.previousSibling.firstChild.id != 'list-heading') {
							event.target.previousSibling.focus();
							event.target.previousSibling.style.backgroundColor = "#ececec";
						} else {
							if(event.target.previousSibling.previousSibling) {
								event.target.previousSibling.previousSibling.focus();
								event.target.previousSibling.previousSibling.style.backgroundColor = "#ececec";
							}
						}
					}
				} else if (event.code == 'Enter'){

					this.$emit('clickListItem', item)
				}
			},
			mouseMove(e) {
				let list = document.getElementById('List');
				let listChilds = list.childNodes;
				for(let i = 0; i < listChilds.length; i++) {
					if (listChilds[i] != e.target) {
						listChilds[i].style.backgroundColor = "#fff";
					}
					else {
						listChilds[i].style.backgroundColor = "#ececec";
					}
				}
			}
			// checkLength(item) {
			// 	if ((item.type == 'heading') && (this.sugLength > 2)) {
			// 		return true
			// 	} else {
			// 		return false
			// 	}
			// }
		},
		created() {
			bus.$on('focusList', () => {
				let list = document.getElementById('List');
				let listChilds = list.childNodes;

				// set the background-color white in case of hover effect
				for(let i = 1; i < listChilds.length; i++) {
					listChilds[i].style.backgroundColor = "#fff";
				}
				//focus on the first none heading element
				if(listChilds[0].firstChild.id == 'list-heading') {
					listChilds[1].focus();
					listChilds[1].style.backgroundColor = "#ececec";
				} else {
					listChilds[0].focus();
					listChilds[0].style.backgroundColor = "#ececec";
				}
			})

		}
	};
</script>

<style scoped>
	#list-heading {
		cursor: default;
		width: 100%;
		margin: 0;
		padding: 0;
		font-size: 20px;
		font-weight: 700;
		align-self: stretch;
		display: flex;
		align-items: center;
	}

	ul {
		list-style: none;
		padding: 0;
	}

	li {
		cursor: pointer;
		height: 40px;
		outline: none;
		display: flex;
		align-items: center;
	}
</style>
<template>
	<div id="input-area"
	@click="showDropdown"
	v-click-outside="hideDropdown">
		<input type="hidden" :value="hiddenDropoffId" id="dropoffloc" name="dropoffloc">
		<div class="vf-input-type-image">
			<img src="../assets/location-pointer.png" alt="">
		</div>
		<div class="vf-input-wrapper">
			<label class="vf-user-input">Where do you want to go?
				<input type="text" placeholder="Start typing to find a location" id="dropoff-input" class="vf-input-element"
				ref="dropoffLocationInput"
				name="fields[dropoffLocation]"
				:value='dropoffLocation'
				@input="dropoffLocation = $event.target.value"
				@keydown="keyPress">
			</label>
			<div class="vf-clear-input-wrapper">
				<button class="vf-clear-input"
				@click="clearInput">X</button>
			</div>
			<div class="triagle"
			v-if="isActive"></div>
			<div class="vf-dropdown-content"
			v-if="isActive">
				<p class="vf-dropdown-message" v-if="noresult">No result found </p>
				<p class="vf-dropdown-message" v-if="nopickup">No pickup location was selected</p>
				<list-dropdown
				:array="dropdownArray"
				:sugLength='sugLength'
				v-slot="{item}"
				@clickListItem="addSelectedDropoff">
					<img class="vf-list-icon" src="../assets/aeroplane.svg"
					v-if="item.type == 'airport'">
					<img class="vf-list-icon" src="../assets/hotel.svg"
					v-if="item.type == 'hotel'">
					<img class="vf-list-icon" src="../assets/cruise.svg"
					v-if="item.type == 'port'">
					<img class="vf-list-icon" src="../assets/place-localizer.svg"
					v-if="item.type == 'generalLocation'">
					{{item.location}}
				</list-dropdown>
			</div>
		</div>
		<div id="error-drop" v-if="error" class="validation-error">
			<span>Please specify a dropoff location</span>
		</div>
	</div>
</template>

<script>
	import List from './ListDropdown.vue';
	import clickOutside from '../directives/clickoutside.js';
	import { bus } from '../main.js';

	const axios = require('axios');

	export default {
	components: {
		'list-dropdown': List
	},
	props: {

	},
	data() {
		return {
			isActive: false,
			selectedDropoff: '',
			selectedPickupLocation: '',
			dropoffLocation: '',
			hiddenDropoffId: '',
			error: false,
			allDestinations: [],
			suggestedDestination: [],
			sugLength: 0
		}
	},
	computed: {
		inputToDropoff: {
			get: function() {
				let suggustedDestinationsIds = [];
				let list = this.suggestedDestination;
				//creates an array with the ids of the suggested destinations
				list.forEach(item => suggustedDestinationsIds.push(item.id))
				let allDestinations = this.allDestinations;
				//search the destinations for duplicated locations
				for(let i = 0; i < suggustedDestinationsIds.length; i++) {
					allDestinations = allDestinations.filter(element => element.id != suggustedDestinationsIds[i])
				}
				//
				let hotels = allDestinations.filter(element => element.type == 'hotel');
				let generalLocation = allDestinations.filter(element => element.type == 'generalLocation');
				let ports = allDestinations.filter(element => element.type == 'port');
				// let notype = allDestinations.filter(element => element.type == 'heading');

				allDestinations = allDestinations.filter(element => element.type == 'airport');


				allDestinations.push(...ports);
				allDestinations.push(...generalLocation);
				allDestinations.push(...hotels);

				//creates an array with teh suggested first and after the others
				list.push(...allDestinations);

				return list
			},
			set: function(newValue) {
					this.suggestedDestination = newValue;
					this.allDestinations = newValue;
			}
		},
		// filters the dropdown list when the user types
		dropdownArray(){
			let array

			if(this.dropoffLocation != ''){
				array = this.filterList(this.dropoffLocation, this.inputToDropoff)
				if (array.length == 0) {
					return []
				} else {
					return array
				}
			} else {
				return this.inputToDropoff
			}

		},
		noresult(){
			return (((this.dropdownArray.length == 0) && (this.selectedPickupLocation)) ? true : false)
		},
		nopickup() {
			return ((this.selectedPickupLocation) ? false : true)
		}
	},
	methods: {
		// help function for filtering the dropdown
		filterList(q, list) {
			function escapeRegExp(s) {
				return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
			}
			const words = q
				.split(/\s+/g)
				.map(s => s.trim())
				.filter(s => !!s);
			const hasTrailingSpace = q.endsWith(" ");
			const searchRegex = new RegExp(
				words
					.map((word, i) => {
						if (i + 1 === words.length && !hasTrailingSpace) {
							// The last word - ok with the word being "startswith"-like
							return `(?=.*\\b${escapeRegExp(word)})`;
						} else {
							// Not the last word - expect the whole word exactly
							return `(?=.*\\b${escapeRegExp(word)}\\b)`;
						}
					})
					.join("") + ".+",
				"gi"
			);
			//
			let newList = [];
				let item;

				for(let i = 0; i < list.length; i++) {
					item = list[i].location
					if(item.search(searchRegex) >= 0) {

						newList.push(list[i])
					}
				}
				return newList
		},
		// open the dropdown when the input is clicked
		showDropdown() {
			document.getElementById('dropoff-input').focus();
			this.isActive = true;
		},
		// hides the dropdown when clicked outside of the component
		hideDropdown() {
			this.isActive = false;
		},
		// stores the selected dropoffLocation and hides the dropdown when an item from the list is clicked
		addSelectedDropoff(event) {
			//get the id for the hidden input
			this.hiddenDropoffId = event.ezBookingId;
			//stores the selected location name
			this.selectedDropoff = event.location;
			//get the location typed from the user
			this.dropoffLocation = event.location;
			setTimeout(() => document.getElementById('dropoff-input').blur(), 30)
			// emit the event
			bus.$emit('DropoffLocationSelected', {pickupLocation: this.selectedPickupLocation, dropoffLocation: this.selectedDropoff, dropoffLocationSlug: event.slug});
			// remove error if exist
			this.error = false;
			// hide the dropdown
			this.isActive = false;
		},
		// stores the selected dropoff location and hides the dropdown when we hit tab and only one is in the dropdown list
		keyPress(event) {
			if( event.code == 'Tab' ) {
				if((this.dropdownArray.length) == 1 && (this.dropdownArray[0].location)) {
				//get the id for the hidden input
				this.hiddenDropoffId = this.dropdownArray[0].ezBookingId;
				//stores the selected location name
				this.selectedDropoff = this.dropdownArray[0].location;
				//get the location typed from the user
				this.dropoffLocation = this.dropdownArray[0].location;
				// emit the event
				bus.$emit('DropoffLocationSelected', {pickupLocation: this.selectedPickupLocation, dropoffLocation: this.selectedDropoff, dropoffLocationSlug: this.dropdownArray[0].slug});
				// remove error if exist
				this.error = false;
				// hide the dropdown
				this.isActive = false;
				//if more than one, prevents default and resets the values
				} else if(this.dropdownArray.length >= 1) {
					this.hiddenDropoffId = '';
					this.selectedDropoff = '';
					this.dropoffLocation = '';
					bus.$emit('noDropoffLocation');
					this.error = true;
					event.preventDefault();
				}
			} else if (event.code == 'ArrowDown'){
				event.preventDefault();
				bus.$emit('focusList');
			} else if (event.code == 'Escape') {
				this.isActive = false;
			}
		},
		clearInput(e) {
			e.preventDefault();
			this.hiddenDropoffId = '';
			this.selectedDropoff = '';
			this.dropoffLocation = '';
			bus.$emit('noDropoffLocation');
		},
		getJSON(key) {
			//help function for json call

			let url = ` https://transferplan-crete.com/getDest${key}.json`;
			let urlSuggested = ` https://transferplan-crete.com/getDest${key}suggested.json`;
			let urlDefault = ' https://transferplan-crete.com/default.json';
			axios.get(url)
				.then(response => {
					if(response.data.data.length != 0){
						this.allDestinations = response.data.data;
					} else {
						axios.get(urlDefault)
						.then(response => this.allDestinations = response.data.data)
					}
				})
			axios.get(urlSuggested)
				.then(response => {
					this.suggestedDestination = response.data.data;
					this.sugLength = this.suggestedDestination.length;
				})
		}
	},
	directives: {
		clickOutside
	},
		created() {
			bus.$on('PickupLocationSelected', data => {
				document.getElementById('dropoff-input').focus();

				this.selectedPickupLocation = data;
				this.allDestinations = [];
				this.suggestedDestination = [];

				let key = data.id

				this.getJSON(key);

				//clears the input when another region is selected
				this.hiddenDropoffId = '';
				this.selectedDropoff = '';
				this.dropoffLocation = '';
				bus.$emit('noDropoffLocation');
				//opens the dropdown
				this.isActive = true

			}),
			bus.$on('clearForm', () => {
				this.hiddenDropoffId = '';
				this.selectedDropoff = '';
				this.dropoffLocation = '';
				this.inputToDropoff = [];
				this.selectedPickupLocation = '';
				this.isActive = false;
			}),
			bus.$on('dropoffError', () => {
				// show error message
				this.error = true;
			}),
			bus.$on('noPickupLocation', () => {
				//clear the input when the origin is erased
				this.isActive = false;
				this.hiddenDropoffId = '';
				this.selectedDropoff = '';
				this.dropoffLocation = '';
				this.inputToDropoff = [];
				this.selectedPickupLocation = '';
				//emit validation error
				bus.$emit('noDropoffLocation');
			}),
			bus.$on('preselect', data => {
				//get the id for the hidden input
				this.hiddenDropoffId = data.destination.ezBookingId;
				//stores the selected location name
				this.selectedDropoff = data.destination.location;
				//get the location typed from the user
				this.dropoffLocation = data.destination.location;
				this.selectedPickupLocation = data.origin;
				//call the json for the dropdown list
				this.getJSON(data.origin.id)
				//remove validation error
				bus.$emit('removeDropoffError')
			}),
			bus.$on('preselect-sidebar', data => {
				//stores the selected location name
				this.selectedDropoff = data.destination.location;
				//get the location typed from the user
				this.dropoffLocation = data.destination.location;
				this.selectedPickupLocation = data.origin;
				//call the json for the dropdown list
				this.getJSON(data.origin.id)
				//remove validation error
				bus.$emit('removeDropoffError')
			})
		}
	};
</script>

<style>
.vf-dropdown-message {
	padding: 15px 0 15px 10px ;
}

</style>
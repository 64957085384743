<template>

	<form id="app" autocomplete="off" method="post">
        <input type="hidden" name="action" value="commerce/cart/update-cart">
		<div id="switch-wrapper">
			<button-switch id="button-switch"></button-switch>
		</div>
		<div id="locations">
			<pickup-selector ref="pickupSelector" id="pickup-selector"></pickup-selector>
			<dropoff-selector ref="dropoffSelector" id="dropoff-selector"></dropoff-selector>
		</div>
		<div id="date-pas">
			<pickup-date-selector id="date"></pickup-date-selector>
			<dropoff-date-selector id="dropoff-date"></dropoff-date-selector>
			<number-passangers id="number-passangers"></number-passangers>
			<button id="submit-button" @click="validation" type="button">FIND TRANSFER</button>
		</div>
	</form>

</template>

<script>
import clickOutside from './directives/clickoutside.js';
import { bus } from './main.js';

const axios = require('axios');

export default {
	name: 'app',
	directives: {
			clickOutside
		},
	data() {
		return {
			pickupValid: false,
			dropoffValid: false,
			pickupDateValid: false,
			dropoffDateValid: false,
			allLocations: [],
			pickupSlug: '',
			dropoffSlug: '',
		}
	},
	methods: {
		validation() {
			this.$refs.pickupSelector.$refs.pickupLocationInput.value = this.$refs.pickupSelector.selectedPickupLocation.location;
			this.$refs.dropoffSelector.$refs.dropoffLocationInput.value = this.$refs.dropoffSelector.selectedDropoff;
			if(this.pickupValid == false){
				bus.$emit('pickupError');
				if (this.dropoffValid == false) {
					bus.$emit('dropoffError');
				}
				if (this.pickupDateValid == false) {
					bus.$emit('pickupDateError');
				}
				if (this.dropoffDateValid == false) {
					bus.$emit('dropoffDateError');
				}
			} else if(this.dropoffValid == false) {
				bus.$emit('dropoffError');
				if (this.pickupDateValid == false) {
					bus.$emit('pickupDateError');
				}
				if (this.dropoffDateValid == false) {
					bus.$emit('dropoffDateError');
				}
			} else if (this.pickupDateValid == false) {
				bus.$emit('pickupDateError');
				if (this.dropoffDateValid == false) {
					bus.$emit('dropoffDateError');
				}
			} else if (this.dropoffDateValid == false) {
				bus.$emit('dropoffDateError');
			} else {
				let form = document.getElementById('app');
				let data = new FormData(form);
				let my = this
				axios.post('/', data)
					.then(function(response) {
						if (response.data.cart) {
							window.location.href = '/results/' + my.pickupSlug + '-to-' + my.dropoffSlug;
						}
					})
			}
		},
		preselect() {
			this.slug = this.$route.path;
			let fromTo = this.slug.split("-to-");
			let originSlug = fromTo[0].slice(1, fromTo[0].length);
			let destinationSlug = fromTo[1];
			let origin;
			let destination;
			for(let i = 0; i < this.allLocations.length; i++) {
				if(this.allLocations[i].slug == originSlug) {
					origin = this.allLocations[i];
				}
				if(this.allLocations[i].slug == destinationSlug) {
					destination = this.allLocations[i];
				}
			}
			if((origin != undefined) && (destination != undefined)){
				bus.$emit('preselect', {origin:origin, destination:destination});
				this.pickupSlug = origin.slug;
				this.dropoffSlug = destination.slug;
			}
		}
	},
	created() {
		bus.$on('PickupLocationSelected', data =>  {
			this.pickupValid = true;
			this.pickupSlug = data.slug;
		}),
		bus.$on('removePickupError', () =>  this.pickupValid = true),
		bus.$on('noPickupLocation', () => this.pickupValid = false)
		bus.$on('DropoffLocationSelected', data => {
			this.dropoffValid = true;
			this.dropoffSlug = data.dropoffLocationSlug;
		}),
		bus.$on('removeDropoffError', () => this.dropoffValid = true),
		bus.$on('noDropoffLocation', () => this.dropoffValid = false),
		bus.$on('selectedPickupDate', () => this.pickupDateValid = true),
		bus.$on('selectedDropoffDate', () => this.dropoffDateValid = true),
		bus.$on('noDropoffDate', () => this.dropoffDateValid = false),
		bus.$on('dropoffDateDisabled', () => this.dropoffDateValid = true)
	},
	mounted() {
		axios.get('https://transferplan-crete.com/locations.json')
			.then(response => {
				this.allLocations = response.data.data;
				this.preselect();
				bus.$emit('allLocations', response.data.data)
			}),
			axios.get('https://transferplan-crete.com/suggested.json')
			.then(response => {
				bus.$emit('suggestedLocations', response.data.data)
			})
	},
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

#app {
	font-family: 'Roboto Condensed', sans-serif;
	margin: 0;
	background-color: #f5a539;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 18px;
}

#switch-wrapper {
	margin-bottom: 20px;
	margin-top: 20px;
}

#locations {
	flex: 100%;
	margin-bottom: 40px;
	display: flex;
	justify-content: space-between;
}

#pickup-selector {
	flex: 1 1 50%;
	margin: 0 10px 0 0 ;
	height: 60px;
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
}

#dropoff-selector {
	flex: 1 1 50%;
	height: 60px;
	margin: 0 0 0 10px ;
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
}

#date-pas {
	flex: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
}

#date {
	flex: 0 1 30%;
	background-color: #fff;
	margin-right: 10px;
	height: 60px;
	display: flex;
	flex-wrap: wrap;
}

#dropoff-date {
	flex: 0 1 30%;
	background-color: #fff;
	margin-right: 10px;
	height: 60px;
	width: 100%;
}

#number-passangers {
	flex: 0 1 15%;
	background-color: #fff;
	height: 60px;
	margin-right: 10px;
	display: flex;
	flex-wrap: wrap;
	align-content: stretch;
	align-items: center;
}

#submit-button {
	flex: 0 1 25%;
	background-color: #0d7dbc;
	color: #fff;
	border: 2px solid #0d7dbc;
	cursor: pointer;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	outline: none;
	font-size: 20px;
}

#button-switch {
	flex: 0 0 30%;
}

@media screen and (max-width: 990px) {
	#app {
		flex-direction: column;
		padding: 4% 2%;
	}
	#locations {
		/*flex-direction: column;*/
		flex-wrap: wrap;
		margin: 0
	}
	#pickup-selector {
		flex: 1 1 100%;
		margin: 5px 0 40px 0;
	}
	#dropoff-selector {
		flex: 1 1 100%;
		margin: 0 0 40px 0;
	}
	#date-pas {
		margin: 0;
		flex-wrap: wrap;
	}
	#date {
		flex: 0 1 48%;
		margin: 0 0px 40px 0;
	}
	#dropoff-date {
		flex: 0 1 48%;
		margin: 0 0 40px 0;
	}
	#number-passangers{
		flex: 0 1 33%;
		margin: 0 0 30px 0;
		height: auto;
		background-color: #f5a539;
	}
	#button-switch {
		margin-bottom: 10px;
	}
	#submit-button {
		flex: 0 1 63%;
		/*max-width: 250px;*/
	}
}
@media screen and (max-width: 1200px) {
	#app {
		font-size: 16px;
	}
}
@media screen and (max-width: 630px) {
	#date {
		flex: 0 1 100%;
		/*margin: 0 0 30px 0;*/
	}
	#dropoff-date {
		flex: 0 1 100%;
		/*margin: 0 0 30px 0;*/
	}
	#number-passangers{
		flex: 0 1 100%;
		margin: 0 0 30px 0;
	}
	#submit-button {
		font-size: 18px;
		flex: 0 1 100%;
	}
}
@media screen and (max-width: 330px) {
	#submit-button {
		font-size: 16px;
	}
	#vf-checkbox-label {
		font-size: 16px;
	}
}
</style>

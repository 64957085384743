import Vue from 'vue';
import App from './App.vue';
import AppSidebar from './AppSidebar.vue';
import AppModal from './AppModal.vue';
import AppPickupSelector from './components/AppPickupSelector.vue';
import AppDropoffSelector from './components/AppDropoffSelector.vue';
import AppPickupDateSelector from './components/AppPickupDateSelector.vue';
import AppSidebarPickupDateSelector from './components/AppSidebarPickupDateSelector.vue';
import AppDropoffDateSelector from './components/AppDropoffDateSelector.vue';
import AppSidebarDropoffDateSelector from './components/AppSidebarDropoffDateSelector.vue';
import AppButtonSwitch from './components/AppButtonSwitch.vue';
import AppPassengerNum from './components/AppPassengerNum.vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

Vue.component('pickup-selector', AppPickupSelector);
Vue.component('dropoff-selector', AppDropoffSelector);
Vue.component('pickup-date-selector', AppPickupDateSelector);
Vue.component('sidebar-pickup-date-selector', AppSidebarPickupDateSelector);
Vue.component('dropoff-date-selector', AppDropoffDateSelector);
Vue.component('sidebar-dropoff-date-selector', AppSidebarDropoffDateSelector);
Vue.component('button-switch', AppButtonSwitch);
Vue.component('number-passangers', AppPassengerNum);

export const bus = new Vue();

export const route = new VueRouter({
    mode: 'history',
    routes: []
});

new Vue({
  render: h => h(App),
  router: route
}).$mount('#app')

new Vue({
  render: h => h(AppSidebar)
}).$mount('#appSidebar')

new Vue({
  render: h => h(AppModal)
}).$mount('#appModal')


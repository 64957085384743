<template>
	<div id="input-pickup"
	@click="showDropdown"
	v-click-outside="hideDropdown">
		<div class="vf-input-type-image">
			<img src="../assets/plane-landing.png" alt="">
		</div>
		<input type="hidden" :value="hiddenPickupId" name="pickuploc" id="pickuploc">
		<div class="vf-input-wrapper">
			<label class="vf-user-input">Pickup location
				<input id="pickup-input" type="text" class="vf-input-element" placeholder="Start typing to find a location"
				ref="pickupLocationInput"
				name="fields[pickupLocation]"
				:value='pickupLocation'
				@input="pickupLocation = $event.target.value"
				@keydown="keyPress">
			</label>
			<div class="vf-clear-input-wrapper">
				<button class="vf-clear-input"
				@click="clearInput">X</button>
			</div>

			<div class="triagle"
			v-if="isActive"></div>
			<div class="vf-dropdown-content"
			v-if="isActive">

				<p class="vf-dropdown-message" v-if="noresult">No result found </p>
				<list-dropdown
				:array="dropdownArray"
				v-slot="{item}"
				@clickListItem="addSelectedPickupLocation">
					<img class="vf-list-icon" src="../assets/aeroplane.svg"
					v-if="item.type == 'airport'">
					<img class="vf-list-icon" src="../assets/hotel.svg"
					v-if="item.type == 'hotel'">
					<img class="vf-list-icon" src="../assets/cruise.svg"
					v-if="item.type == 'port'">
					<img class="vf-list-icon" src="../assets/place-localizer.svg"
					v-if="item.type == 'generalLocation'">

					{{item.location}}
				</list-dropdown>
			</div>
		</div>
		<div id="error-pick" v-if="error" class="validation-error">
			<span>Please specify a pickup location</span>
		</div>

	</div>
</template>

<script>
	import List from './ListDropdown.vue';
	import clickOutside from '../directives/clickoutside.js'
	import { bus } from '../main.js';


	export default {
		directives: {
			clickOutside
		},
		components: {
			'list-dropdown': List
		},
		data() {
			return {
				isActive: false,
				selectedPickupLocation: '',
				pickupLocation: '',
				hiddenPickupId: '',
				error: false,
				allLocations: {},
				suggestedLocations: {},
				slug: ''
			}
		},
		computed: {
			//filters the dropdown list when the user types
			inputData() {
				let suggestedLocationsIds = [];
				let list = this.suggestedLocations;
				//creates an array with the ids of the locations
				list.forEach(item => suggestedLocationsIds.push(item.id))

				let allLocations = this.allLocations;
				//search for duplicated locations
				for(let i = 0; i < suggestedLocationsIds.length; i++) {
					allLocations = allLocations.filter(element => element.id != suggestedLocationsIds[i])
				}
				//creates an array with teh suggested first and after the others
				list.push(...allLocations);

				return list
			},
			dropdownArray(){
				let array;
				let returnValue;
				// console.log(this.pickupLocation)
				if(this.pickupLocation != '') {

					array = this.filterList(this.pickupLocation, this.inputData);

				//checks if the array is empty and if so returns an array with the same format as the inputdata
					if(array.length == 0) {
						returnValue = [];
					}
					else {
						returnValue = array;
					}
				} else if (this.pickupLocation == '') {
					returnValue = this.inputData;
				}
				return returnValue
			},
			noresult(){
					return ((this.dropdownArray.length == 0) ? true : false)
			}
		},
		methods: {
			// help function for filtering the dropdown
			filterList(q, list) {

				function escapeRegExp(s) {
					return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
				}
				const words = q
					.split(/\s+/g)
					.map(s => s.trim())
					.filter(s => !!s);
				// console.log(words)
				const hasTrailingSpace = q.endsWith(" ");
				const searchRegex = new RegExp(
					words
						.map((word, i) => {

							if (i + 1 === words.length && !hasTrailingSpace) {
								// The last word - ok with the word being "startswith"-like
								return `(?=.*\\b${escapeRegExp(word)})`;
							} else {
								// Not the last word - expect the whole word exactly
								return `(?=.*\\b${escapeRegExp(word)}\\b)`;
							}
						})
						.join("") + ".+",
					"gi"
				);

				let newList = [];
				let item;

				for(let i = 0; i < list.length; i++) {
					item = list[i].location
					if(item.search(searchRegex) >= 0) {

						newList.push(list[i])
					}
				}
				return newList
			},
			showDropdown() {
				document.getElementById('pickup-input').focus();
				this.isActive = true;
			},
			// hides the dropdown when clicked outside of the component
			hideDropdown() {
				this.isActive = false;
			},
			// stores the selected pickup location, pass it to the bus and hides the dropdown when the item is clicked
			addSelectedPickupLocation(event) {
				//get the id for the hidden input
				this.hiddenPickupId = event.ezBookingId;
				//get the selected location name for the displayed input
				this.pickupLocation = event.location;
				//get the selected item to pass it to the dropoff
				this.selectedPickupLocation = event;
				// emit the event
				setTimeout(() => {bus.$emit('PickupLocationSelected', this.selectedPickupLocation);}, 30)
				// hide the dropdown
				setTimeout(() => this.isActive = false, 20)
				// if an error exits, it removes it
				this.error = false;
			},
			// stores the selected pickup location, pass it to the bus and hides the dropdown when we hit tab and only one is in the dropdown list
			keyPress(event) {
				this.isActive = true;
				if( event.code == 'Tab' ) {
					if((this.dropdownArray.length) == 1) {
						//get the id for the hidden input
						this.hiddenPickupId = this.dropdownArray[0].ezBookingId;
						//get the selected location name for the displayed input
						this.pickupLocation = this.dropdownArray[0].location;
						//get the selected item to pass it to the dropoff
						this.selectedPickupLocation = this.dropdownArray[0];
						// emit the event
						setTimeout(() => bus.$emit('PickupLocationSelected', this.selectedPickupLocation), 30)
						//hide the dropdown
						this.isActive = false;
						// if an error exits, it removes it
						this.error = false;
						//if more than one, prevents default and resets the values
					} else if(this.dropdownArray.length >= 1) {

						this.hiddenPickupId = '';
						this.pickupLocation = '';
						this.selectedPickupLocation = '';
						// emit the event
						setTimeout(() => bus.$emit('noPickupLocation'), 30)
						event.preventDefault();

						this.error = true;
					}
				} else if (event.code == 'ArrowDown'){
					event.preventDefault();
					bus.$emit('focusList', 'pickup');
				}
				else if (event.code == 'Backspace'){
					this.hiddenPickupId = '';
					this.selectedPickupLocation = '';
					// emit the event
					setTimeout(() => bus.$emit('noPickupLocation'), 30)
				} else if (event.code == 'Escape'){
					this.isActive = false;
				}
			},
			clearInput(e) {
				e.preventDefault();
				this.pickupLocation = '';
				this.selectedPickupLocation = '';
				this.hiddenPickupId = '';
				// emit the event
				setTimeout(() => bus.$emit('noPickupLocation'), 30)
			}
		},
		created() {
			//clear the form
			bus.$on('clearForm', () =>{
				this.pickupLocation = '';
				this.selectedPickupLocation = '';
				this.hiddenPickupId = '';

			}),
			bus.$on('pickupError', () => {
				this.error = true;
			}),
			bus.$on('allLocations', data => this.allLocations = data),
			bus.$on('suggestedLocations', data => this.suggestedLocations = data),
			bus.$on('preselect', data => {
				//get the id for the hidden input
				this.hiddenPickupId = data.origin.ezBookingId;
				//get the selected location name for the displayed input
				this.pickupLocation = data.origin.location;
				//get the selected item to pass it to the dropoff
				this.selectedPickupLocation = data.origin;
				// emit event to remove the error
				bus.$emit('removePickupError');
			}),
			bus.$on('preselect-sidebar', data => {
				//get the selected location name for the displayed input
				this.pickupLocation = data.origin.location;
				//get the selected item to pass it to the dropoff
				this.selectedPickupLocation = data.origin;
				// emit event to remove the error
				bus.$emit('removePickupError');
			})
		}
	};

</script>

<style>
.vf-input-type-image {
	width: 60px;
	height: 60px;
	background-color: #03a9f4;
	flex: 0 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.vf-input-wrapper{
	width: calc(100% - 60px);
	height: 60px;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.vf-user-input {
	height: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 0 0 90%;
	color: #03a9f4;
	box-sizing: border-box;
	padding-left: 6px;
	padding-bottom: 1%;
	width: 98%;
	font-weight: 600;
	margin-bottom: 0 !important;
}

.vf-input-element {
	color: #333333;
	margin: none;
	border: 1px solid #fff;
	padding-top: 4px;
	outline: none;
	/*font-size: 18px;*/
}

.vf-clear-input-wrapper {
	flex: 0 0 10%;
	height: 60px;
	box-sizing: border-box;
	padding-bottom: 1%;
	padding-top: 4px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.vf-clear-input {
	background-color: #fff;
	border: none;
	font-size: 18px;
	font-weight: 500;
	outline: none;
}

.vf-list-icon {
	width: 20px;
	height: 20px;
	margin-right: 20px;
}

.vf-dropdown-content {
	background-color: #fff;
	padding: 5px 15px;
	position: relative;
	top: -15px;
	width: 100%;
	z-index: 999999999;
	border: 1px solid #666;
	max-height: 400px;
	overflow: auto;
}



.validation-error {
	background-color: #E34234;
	color: #fff;
	padding: 2px 5px;
	font-size: 12px;
	width: 100%;
}

/*placeholder color*/
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #C0C6C9;
}
::-moz-placeholder { /* Firefox 19+ */
	color: #C0C6C9;
}
:-ms-input-placeholder { /* IE 10+ */
	color: #C0C6C9;
}
:-moz-placeholder { /* Firefox 18- */
	color: #C0C6C9;
}
@media screen and (min-width: 600px) {
	.triagle {
	/*	width: 15px;
		height: 5px;*/
		/*background-color: black;*/
		/*content: " ";*/
		position: relative;
		bottom: 15px;
		left: 10%;
		margin-left: -5px;
		border-width: 15px;
		border-style: solid;
		border-color: transparent transparent #666 transparent;
		z-index: 10000000000;
	}

	/*.triagle::after {
		position: relative;
		bottom: 15px;
		left: 10%;
		margin-left: -5px;
		border-width: 15px;
		border-style: solid;
		border-color: transparent transparent black transparent;
		z-index: 1;
	}*/

	.triagle::after {
		content: "";
		position: absolute;
		bottom: -16px;
		left: -14px;
		margin-left: 0px;
		border-width: 14px;
		border-style: solid;
		border-color: transparent transparent #fff transparent;
		z-index: 10000000001;
	}

	/*.triagle::after {
		content: " ";
		position: absolute;
		bottom: 100%;
		left: 10%;
		margin-left: -5px;
		border-width: 15px;
		border-style: solid;
		border-color: transparent transparent black transparent;
		z-index: 1;
	}*/
}

@media screen and (max-width: 600px) {
	.vf-dropdown-content {
		top: 0;
		border-top: 0;
	}
	.vf-input-wrapper {
		position: relative;
	}
	.vf-clear-input-wrapper {
		position: absolute;
		right: 0px;
	}
}
@media screen and (max-width: 330px) {
	.vf-user-input {
		font-size: 16px;
	}
	.vf-input-element {
		font-size: 16px;
	}
	.vf-dropdown-content {
		font-size: 16px;
	}
}
</style>
<template>
<div id="help-div" v-click-outside="inactive">
	<div id="hideMe" class="">
		<div class="vf-input-type-image">
			<img src="../assets/calendar.png" alt="">
		</div>
		<div class="date-time-wrapper">
			<div class="date-time-block">
				<div class="calendar-wrapper">
					<input type="hidden" id="dropoff"  name="fields[returnDate]" :value="hiddendropoffDate" >
					<label>Return date</label>
					<datepicker
					id="dropoff-datepicker"
					ref="dropoff-datepicker"
					v-click-outside="hideCalendar"
					input-class="datepicker-input"
					calendar-class="datepicker-calendar"
					wrapper-class="datepicker-wrapper"
					v-model="dropoffDate"
					:disabledDates="dropOffPicker.disabledDates"
					@input="changeValue"></datepicker>
				</div>
				<div class="time-display"
				@click="active">
					<label>Time</label>
					<span> {{hours}} : {{minutes}} </span>
				</div>

			</div>
			<div class="time-dropdown"
				v-if="isActiveDate"  >
				<div class="time-message">
					<span>
						Pickup time from hotel or destination. You can change this at any time. Estimated time for your ride:
					</span>
					<span>
						<strong>{{distance}}</strong>
					</span>
				</div>
				<div class="time-select">
				<select name="" id=""
				v-model="hours"
				@change="checkDropdown">
					<option value="Hr" disabled>Hr</option>
					<option
					v-for="hour in hourArray"
					:key="hour.id"
					:value="hour">
						{{ hour }}
					</option>
				</select>
				<select name="" id=""
				v-model="minutes"
				@change="checkDropdown">
					<option value="Min" disabled>Min</option>
					<option
					v-for="minute in minutesArray"
					:key="minute.id"
					:value="minute">
					{{ minute }}
					</option>
				</select>
				</div>
			</div>
		</div>
		<div id="error-drop-date" v-if="error" class="validation-error">
			<span>Please specify a return date and time</span>
		</div>
	</div>
</div>
</template>


<script>
	import { bus } from '../main.js';
	import clickOutside from '../directives/clickoutside.js'
	// DatePicker
	import Datepicker from 'vuejs-datepicker';

	export default {
		directives: {
			clickOutside
		},
		components: {
			'datepicker': Datepicker
		},
		data() {
			return {
				timeDrop: '',
				pickupDate: '',
				dropoffDate: '',
				distance: '',
				error: false,
				enabled: true,
				hasOpened: false,
				hours: 'Hr',
				minutes: 'Min',
				hourArray: ['06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
				minutesArray: ['00', '10', '20', '30', '40', '50'],
				isActiveDate: false
			}
		},
		computed: {
			dropOffPicker() {
				return{
					disabledDates: {
						to: ((!this.pickupDate) ? new Date() : new Date(+new Date(this.pickupDate)))
					}
				}
			},
			hiddendropoffDate: {
				get: function() {
					// formats the date passed to the hidden input
					let dateTime;
					if((this.dropoffDate !='') && (this.timeDrop != '')){
						dateTime = this.dropoffDate.toLocaleString('fr-FR').substr(0,10) + ', ' + this.timeDrop;

						if(this.enabled){
							bus.$emit('selectedDropoffDate', this.enabled);
							// this.error = false;
						}
					} else if ((this.dropoffDate =='') && (this.timeDrop == '')) {
						dateTime = ''
					}
					return dateTime
				},
				set: function(newValue) {
					this.dropoffDate = newValue;
					this.timeDrop = newValue;
				}
			}
		},
		methods: {
			changeValue(event) {
				this.dropoffDate = event;
			},
			active() {
				this.isActiveDate = true;
			},
			// hides time dropdown
			inactive() {
				this.isActiveDate = false;
			},
			checkDropdown() {
				if((this.hours != 'Hr') && (this.minutes != 'Min')) {
					this.isActiveDate = false;
					this.timeDrop = this.hours + ':' + this.minutes;
					if(!this.hasOpened) {
						setTimeout(() => bus.$emit('moveToNum'), 10)
					}
					if(this.dropoffDate) {
						this.error = false;
					}
				}
			},
			hideCalendar() {
				this.$refs['dropoff-datepicker'].close();
			}
		},
		created() {
			bus.$on('selectedPickupDateSidebar', data => {
				if(data.pickupDate != '') {
					this.pickupDate = data.pickupDate;
					this.distance = data.distance;
				}
				if (this.dropoffDate != '' && (new Date(this.dropoffDate).getTime() <= new Date(data.pickupDate).getTime())) {
					this.dropoffDate = new Date(+new Date(data.pickupDate) + 86400000)
				}
			}),
			bus.$on('dropoffTime', data => {
				this.timeDrop = data;
			}),
			bus.$on('moveToDropoff', () => this.$refs['dropoff-datepicker'].showCalendar()),
			bus.$on('preselect-sidebar', data => {
				let month;
				let tempMonth = data.returnDate[1].split('');
				if (tempMonth[1] == '0') {
					month = '09'
				} else {
					tempMonth[1] = (Number(tempMonth[1]) - 1).toString();
					month = tempMonth.join('');
				}
				this.dropoffDate = new Date(data.returnDate[2], month, data.returnDate[0]);
				this.hours = data.returnTime[0].trim();
				this.minutes = data.returnTime[1].trim();
				this.timeDrop = this.hours + ':' + this.minutes;
				this.dropOffPicker.disabledDates = {
					to: ((!this.pickupDate) ? new Date() : new Date(+new Date(this.pickupDate)))
				}
			})
		}
	};
</script>

<style scoped>


#help-div {
	width:100%;
}

#hideMe {
	display: flex;
	flex-wrap: wrap;
}

.time-dropdown select option {
	text-align: center;
}

.is-disable {
	opacity: 0.4;
	pointer-events: none;
	z-index: 0;
}

@media screen and (max-width: 990px) {
	.time-dropdown {
		left: 30%;
	}
}

</style>

<style>
#dropoff-datepicker {
	width: 100%;
	border: none;
	height: 32px;
}
</style>